.center {
  margin-left: auto;
  margin-right: auto;
}

.large-text {
  font-size: 32px !important;
  font-weight: 700;
  color: black;
}

.cont1 {
  height: 100%;
  width: 100%;
  padding-right: 0px;
}
.left-top {
  margin-left: 20px;
  margin-top: 10px;
}

.padding {
  padding: 5px;
}

#founder {
  background-image: url("../../images/photos/virendra-1.jpg");
  margin-top: 5px;
  width: 300px;
  height: 350px;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-position: top center;
  justify-content: center;
  object-fit: cover;
}
.founder {
  background-image: url("../../images/photos/virendra-1.jpg");
  margin-top: 5px;
  width: 300px;
  height: 350px;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-position: top center;
}
.f2 {
  background-image: url("../../images/photos/founder2.jpg") !important;
}

#founder1 {
  background-image: url("../../images/photos/virendra-2.jpg");
  margin-top: 5px;
  width: 340px;
  height: 500px;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-position: top center;
}

.picture {
  height: 670px;
}
@media screen and (max-width: 1010px) {
  #founder {
    width: 230px;
    height: 330px;
  }
}
@media screen and (max-width: 768px) {
  .picture {
    height: 300px;
  }
  .mob-margin {
    margin-top: 30px;
  }
  .mob-margin-last {
    margin-bottom: 5px;
  }
  #founder {
    width: 250px;
    height: 370px;
  }
}
