* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Playfair Display", serif;
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Oswald', sans-serif;
  font-size: 20px; */
  
}
html {
  overscroll-behavior: none;
}
body {
  background-color: #f6f6f6;
}

.home,
.services,
.products,
.contact-us,
.sign-up,
.marketing,
.consulting {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.home {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.services {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact-us {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.marketing {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.consulting {
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
