.picture1 {
  height: 450px;
}

/* .center1 {
  margin-left: auto;
  margin-right: auto;
} */

.cont {
  height: 500px;
  width: 100%;
  padding-right: 0px;
}
