.spinn {
  margin-bottom: 500px;
  text-align: center;
  /* margin-left: auto;
    margin-right: auto; */
}

.heading {
  text-align: center;
  margin: 15px;
}

.title-img2 {
  font-weight: 600;
  font-size: 25px;
  opacity: 0.8;
  text-align: center;
  margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .title-img2 {
    font-size: 18px;
  }
}
