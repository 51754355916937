.navbar {
  /* background: linear-gradient(90deg, #000 0%, #bdc3c7 100%); */
  background: #363538;
  /* linear-gradient(to right, black 0, gray 50%, black 100%); */
  height: 90px;
  display: flex;
  /* justify-content: center; */
  align-items: flex-end;
  font-size: 17px;
  color: black !important;
}
/* c4d7e6 */

.navbar-logo {
  color: #fff;
  /* justify-self: start; */
  margin-left: 90px;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 23px;
}
.navbar-logo:hover {
  color: gray;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-gap: 2px;
  list-style: none;
  text-align: center;
  align-items: end;
  width: 70vw;
  justify-content: center;
  margin-right: 0px;
  color: #fff;
}
/* .customLogo {
  background-image: url("../images/companyLogo.png");
} */
.background {
  /* position: absolute; */
  margin-top: 0 !important;
  height: 70px !important;
  width: 70px !important;
  background-color: white;
  border-radius: 0%;
  margin-left: 10px;
}
.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  color: white;
  margin-right: 5px;
  margin-top: 8px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin-bottom: 12px;
}

.nav-links:hover {
  color: white;
  background-color: gray;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}
/*  #1888ff;*/
.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
.logo {
  height: 70px;
  width: 60px;
}

@media screen and (max-width: 960px) {
  .navbar {
    /* position: fixed; */
    z-index: 100;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    align-items: center;
    width: 60%;
    height: 87vh;
    position: fixed;
    top: 89px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1000;
    flex: 1;
  }
  .nav-menu.active {
    background: #363538;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 101;
  }

  .nav-links {
    position: relative;
    margin-top: 0px !important;
    text-align: center !important;
    padding: 1px;
    width: 100%;
    /* display: table; */
    /* padding: 2px; */
  }
  .nav-item {
    margin: 0px !important;
  }

  /* .nav-links:hover {
    color: white;
    background-color: gray;
    border-radius: 0px;
    transition: all 0.2s ease-out;
  } */

  .navbar-logo {
    position: absolute;
    margin-left: 20px;
    bottom: 20px;
    left: 6px;
    font-size: 16px;
    transform: translate(25%, 50%);
  }
  .background {
    height: 60px !important;
    width: 60px !important;
    bottom: 15px;
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: -3px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: gray;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: gray;
    transition: 250ms;
  }

  button {
    display: none;
  }
}
