#avatar {
  /* This image is 687 wide by 1024 tall, similar to your aspect ratio */
  background-image: url("../../images/avatar.png");
  /* make a square container */
  margin-top: 5px;
  width: 200px;
  height: 200px;

  /* fill the container, preserving aspect ratio, and cropping to fit */
  background-size: cover;

  /* center the image vertically and horizontally */
  background-position: top center;

  /* round the edges to a circle with border radius 1/2 container size */
  border-radius: 50%;
}
.center {
  margin-left: auto;
  margin-right: auto;
}

.title-img {
  font-weight: 700;
  font-size: 22px;
  text-align: center;
}
.title-img2 {
  font-weight: 600;
  font-size: 18px;
  opacity: 0.8;
  text-align: center;
  margin-bottom: 5px;
}
.read-or-hide {
  color: rgb(60, 140, 245);
  cursor: pointer;
}

.margin {
  margin-bottom: -14px;
}
#founder2 {
  background-image: url("../../images/photos/virendra-1.jpg");
  margin-top: 5px;
  width: 250px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-position: top center;
}
.mob {
  display: none;
}

@media screen and (max-width: 768px) {
  .desk {
    display: none;
  }
  .mob {
    display: block;
  }
}
