.center {
  text-align: center;
}

.back {
  background-color: gray;
}

.large-text {
  font-size: 25px !important;
  font-weight: 400 !important;
}

.sizetext {
  font-size: 13px !important;
  font-weight: 500 !important;
}
.accordion-normal {
  height: 100%;
  width: 100%;
  padding: 10px;
  margin: 0px !important;
}
.accordion-back {
  background-color: #363538 !important;
  height: 100%;
  width: 100%;
  padding: 10px;
  margin: 0px !important;
  color: white;
}
.darkMode {
  background-color: black !important;
  color: white !important;
}
