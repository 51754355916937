.test1 {
  font-family: "Open Sans", sans-serif;
}

.test2 {
  font-family: "Roboto", sans-serif;
}

.margin {
  margin-bottom: 125px !important;
}

.margin-mob {
  margin-top: 50px !important;
}
.animate {
  animation: fadeIn linear 2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .margin-mob {
    margin-top: 0px !important;
  }
  .para {
    margin-top: 10px;
  }
}
