.tail {
  background-color: #363538;
  width: auto;
  height: 210px;
  margin-top: 0px;
  color: gainsboro;
  margin-bottom: 0px;
  padding: 15px;
}
.background {
  position: absolute;
  margin-top: 0 !important;
  height: 60px;
  width: 60px;
  background-color: white;
  border-radius: 50%;
  margin-left: 8px;
}
.op {
  opacity: 0.5;
}

.large-text {
  font-size: large;
}

.icon {
  color: gainsboro;
}
.inline-block {
  display: flex;
  justify-content: center;
  gap: 5px;
}

/* .center {
  justify-content: center;
} */
.bot {
  /* margin-bottom: 0px; */
  background-color: green;
  margin-top: 50px;
}

.pink {
  background-color: pink;
}
